<section id="header" style="background: #445069 !important">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a [routerLink]="['/']" class="navbar-brand">
          <h1 class="text-white display-6">UMYO Realtor</h1>
          <!-- <img src="../proliving/pro_liv.jpeg" class="img-fluid rounded-circle" style="width:4rem; height:4rem;"> -->
        </a>
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
             
            <a class="nav-link nav-item" [routerLink]="['/']">Home</a>
            
           
            <a class="nav-link nav-item" href="https://vibestream.tv"
              >Realtors Talk</a
            >
          
         
            <a class="nav-link nav-item" href="https://vibetalktv.com/">Podcast</a>
          
         
            <a href="https://uchatyes.com/" class="nav-link nav-item">ChatGpt</a>
          
         
            <a href="https://umyotweets.com/" class="nav-link nav-item"
              >RealtorsTweet</a
            >
          
         
            <a href="https://Live.umyoconvo.site/" class="nav-link nav-item"
              >Podcast</a
            >
          
         
            <a href="https://Umyoappt.site/" class="nav-link nav-item"
              >Booking</a
            >
          
         
            <a href="https://Umyoevents.site/" class="nav-link nav-item"
              >Events</a
            >
          
         
            <a href="https://UPMRadio.site/" class="nav-link nav-item"
              >Radio</a
            >
          
         
            <a class="nav-link nav-item" [routerLink]="['/cf']">Card Features</a>
          
         
            <a class="nav-link nav-item" [routerLink]="['/package']"
              >View Services</a
            >
          
         
            <a class="nav-link nav-item" [routerLink]="['/ai']"
              >Additional Features</a
            >
          </div>
          <div class="d-flex m-3 ms-auto">
            <!-- <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">UMYO</p> -->
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-5 pb-3 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/']">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://vibestream.tv"
                  >Realtors Talk</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://vibetalktv.com/">Podcast</a>
              </li>
              <li class="nav-item">
                <a href="https://uchatyes.com/" class="nav-link">ChatGpt</a>
              </li>
              <li class="nav-item">
                <a href="https://umyotweets.com/" class="nav-link"
                  >RealtorsTweet</a
                >
              </li>
              <li class="nav-item">
                <a href="https://Live.umyoconvo.site/" class="nav-link"
                  >Podcast</a
                >
              </li>
              <li class="nav-item">
                <a href="https://Umyoappt.site/" class="nav-link"
                  >Booking</a
                >
              </li>
              <li class="nav-item">
                <a href="https://Umyoevents.site/" class="nav-link"
                  >Events</a
                >
              </li>
              <li class="nav-item">
                <a href="https://UPMRadio.site/" class="nav-link"
                  >Radio</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cf']">Card Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/package']"
                  >View Services</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ai']"
                  >Additional Features</a
                >
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- Hero Start -->
</section>

<!------- tab section end ---------->

<section id="acc">
  <div class="accordion-body">
    <div class="accordion">
      <div class="container">
        <h1>
          UMYO Stylist - Corporate Ambassador Sponsorships - Name - Image -
          Likeness
        </h1>
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-2 col-md-3">
            <h6>Basic Sponsors</h6>
            <p class="text-center">(15k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Custom Sponsors</h6>
            <p class="text-center">(25k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Premium Sponsors</h6>
            <p class="text-center">(35k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Bronze Sponsors</h6>
            <p class="text-center">(50k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Bronze Sponsors</h6>
            <p class="text-center">(50k monthly)</p>
          </div>
          <div class="col-md-1"></div>
          <p class="mt-5 text-center">
            *All Corporate Sponsorships includes ( Double Standing Interactive
            Kiosk/Banner at School of sponsored athlete with Logo” and below
            features
          </p>
          <h4 class="text-center mt-3">
            SEE ADDITIONAL INCENTIVES FOR COMMUNITY COLLABORATION
          </h4>
          <h6 class="text-center mt-3">
            See what’s Included when community brands for you!
          </h6>
        </div>

        <hr />
        <div class="container-content">
          <div class="label">Uchatyes.com</div>
          <div class="content">
            <p>
              What is uChat? uChat is an AI-powered conversational agent based
              on the GPT (Generative Pre-trained Transformer) architecture. It
              can understand and generate human-like responses to text-based
              conversations.
            </p>
            <p class="mx-auto">
              How does uChat work? uChat uses deep learning techniques to
              understand and generate text. It has been trained on a vast amount
              of data and can generate coherent responses based on the input it
              receives.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Standing Kiosk</div>
          <div class="content">
            <p>
              also known as a stand-up kiosk, is an interactive display system
              that is designed for use while standing. These kiosks are
              typically equipped with a touchscreen interface, allowing users to
              interact with the kiosk's software or application to perform
              various tasks. Standing kiosks are commonly found in a variety of
              public spaces and commercial environments, including: education
              Institutions. We will provide standing kiosks for educational
              institutions on behalf of sponsors.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Sports Magazine</div>
          <div class="content">
            <p>
              The new Virtual Audio Video and Image Magazine directories help
              businesses brand logos, ads and virtual business cards to
              educational institutions, organizations, nonprofits and business
              magazines through virtual interactive magazines. Small businesses
              can register to be part of the directory, create their profile and
              add products, goods and services they offer. Virtual magazines are
              available with articles for many types of businesses, including
              finance, family, sports, food, entertainment, business, blogs,
              fashion, social media influencers, salons, attorneys, restaurants,
              retail, article of the month and more
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Coupon Kiosk</div>
          <div class="content">
            <p>
              A virtual version of groupon, only its interactive with video and
              audio ads. Features include the option to share on popular social
              media platforms, use QR codes and share the magazine through email
              and text. Customers can then text the business directly, call or
              link to a business, website or place of contact with the click of
              a mouse.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Commercial</div>
          <div class="content">
            <p>
              UMYO will create a commercial for your business monthly" which
              refers to activities, products, or services that are involved in
              or connected to your entity. We will encompass everything related
              to your business as well as advertisements and promotions, often
              in the form of social media. These are messages created with the
              purpose of promoting and selling a product or service to
              consumers.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">EPK</div>
          <div class="content">
            <p>
              It is our digital interactive online promotional tool used
              primarily for businesses of all types for promotional purposes. We
              will create an EPK collection of digital files and information
              that provide essential details about your business rather that be
              a person, group, company, product, or event, making it easy for
              media professionals, journalists, event organizers, and others to
              quickly access and use this information for publicity and
              reporting.EPKs include high-quality images, logos, and other
              graphics that can be used by media outlets for promotional
              materials or articles.
            </p>
            <p>
              Videos: Video clips, such as trailers, music videos, or
              promotional videos, can be included to give a visual overview of
              the subject.Social Media Links: Links to the subject's social
              media profiles for further engagement and updates.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Google Ads</div>
          <div class="content">
            <p>
              is an online advertising platform developed by Google. It is one
              of the most popular and widely used advertising networks on the
              internet. Google Ads enables businesses and advertisers to display
              their advertisements on Google's search engine results pages
              (SERPs), as well as on other Google properties and a vast network
              of partner websites, which make up the Google Display Network.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">SEO</div>
          <div class="content">
            <p>
              It is a set of practices and strategies aimed at improving a
              website's visibility in search engine results, primarily on search
              engines like Google, Bing, and Yahoo. The ultimate goal of SEO is
              to increase organic (non-paid) traffic to a website by ensuring
              that it ranks higher in search engine results pages (SERPs) for
              relevant and valuable search queries.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">SMS Marketing</div>
          <div class="content">
            <p>
              SMS marketing, also known as text message marketing, is a
              marketing strategy that involves sending short text messages to a
              group of recipients with the goal of promoting products, services,
              events, or offers. This marketing method utilizes SMS (Short
              Message Service) technology, which allows businesses to reach
              customers directly on their mobile phones. SMS marketing can be an
              effective way to engage with customers, deliver time-sensitive
              information, and drive sales.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Unlimited Cards</div>
          <div class="content">
            <p>
              UMYO offers a wide selection of cards for you to choose from. As a
              user, you have the flexibility to create an unlimited number of
              cards by utilizing a variety of template options.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">B2B Branding</div>
          <div class="content">
            <p>
              Commerce or transaction where businesses sell products, services,
              or goods to other businesses rather than selling directly to
              consumers (B2C - Business-to-Consumer). In a B2B context, the
              customers are other companies, and the transactions typically
              involve larger quantities, longer-term contracts, and specialized
              products or services tailored to the needs of the business buyer.
              B2B interactions are common in various industries, including
              manufacturing, distribution, technology, and services.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Email Branding</div>
          <div class="content">
            <p>
              Similar to SMS branding, Email branding refers to the practice of
              customizing and maintaining a consistent and professional visual
              identity for your emails. This visual identity includes elements
              like your company's logo, color scheme, typography, and other
              design elements that represent your brand. Effective email
              branding helps your emails stand out, build trust, and reinforce
              your brand identity with your recipients.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Sponsor an Ambassador</div>
          <div class="content">
            <p>
              refers to a significant change in collegiate athletics in the
              United States. It pertains to the rights of college athletes to
              profit from their own name, image, and likeness. Prior to these
              changes, NCAA (National Collegiate Athletic Association) rules
              prohibited college athletes from earning compensation or benefits
              beyond their scholarships. However, with the introduction of NIL
              rules, college athletes are now allowed to:
            </p>
            <p>
              Endorse Products and Brands: Ambassadors, can enter into
              endorsement deals and promotional contracts with companies and
              brands, allowing them to use their name, image, and likeness for
              marketing and advertising.Monetize Social Media: College athletes
              can profit from their social media platforms by promoting
              products, sharing sponsored content, and leveraging their online
              presence.Sell Autographs and Merchandise: They can sell
              autographs, memorabilia, and merchandise with their names and
              likenesses, benefiting from their personal brand.Participate in
              Camps and Clinics: Athletes can host or participate in sports
              camps and clinics, charging fees for coaching or
              participation.Appear in Video Games and Media: They have the
              opportunity to appear in video games, films, and other media
              projects and earn compensation for their involvement.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">MP3 Branding</div>
          <div class="content">
            <p>
              umyosms.com , similar to constant contact, our MP3 branding"
              refers to the process of adding branding elements, such as
              metadata or audio watermarks, to an MP3 audio file. This is
              commonly done in various contexts, including the music and podcast
              industries, to protect intellectual property, identify the creator
              or source, or enhance the listener's experience.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">
            Virtual Website - The first of its kind virtual reality touch screen
            kiosk website.
          </div>
          <div class="content">
            <p>
              Virtual Reality (VR) Websites: These are websites designed to be
              experienced in virtual reality. Users can navigate and interact
              with the website's content using Virtual interactive experiences.
              VR websites often provide immersive and 3D experiences,
              particularly for gaming, real estate tours, or immersive
              storytelling. Image a touch screen video, audio image website that
              gives
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Marketing Manager</div>
          <div class="content">
            <p>
              A Marketing Manager is a professional responsible for planning,
              executing, and overseeing marketing strategies and activities for
              an organization, business, or product. Their primary objective is
              to promote the brand, attract customers, and drive sales or
              engagement. Developing and implementing marketing strategies and
              plans to achieve the organization's objectives. This includes
              defining target audiences, setting goals, and selecting marketing
              channels.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Campaign Marketing</div>
          <div class="content">
            <p>
              Campaign marketing, also known as marketing campaigns, refers to a
              coordinated series of marketing activities and initiatives with a
              specific goal, message, or objective. These campaigns are designed
              to promote a product, service, event, or cause to a target
              audience. They often have a defined start and end date and are
              structured to achieve specific outcomes.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Commercial/ Contests</div>
          <div class="content">
            <p>
              Why invest thousands of dollars in a commercial that only a
              handful of people will see when you can pay a modest monthly fee
              for the community to generate commercials on your behalf? Now,
              thousands of individuals are casting their votes for the best
              commercials promoting your business, and the winning creators from
              the community receive prizes.have a defined start and end date and
              are structured to achieve specific outcomes.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Social Media Influencer</div>
          <div class="content">
            <p>
              A social media influencer is an individual who has established
              credibility and a dedicated following on one or more social media
              platforms. Influencers have the ability to persuade and impact the
              opinions, behaviors, and purchasing decisions of their followers
              due to their expertise, authenticity, and the trust they've built
              within their niche or industry.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Youth social media branding</div>
          <div class="content">
            <p>
              Youth social media branding refers to the practice of creating and
              managing a distinct and appealing online identity for individuals,
              organizations, or businesses targeting a younger demographic on
              social media platforms. This branding strategy is designed to
              resonate with and engage with young people, who often have unique
              preferences, interests, and behaviors in the digital realm. Also
              using Youth to brand your business and support your business
              through social media. For example: Your Banner at educational
              institutions.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Full Platform Access & Features</div>
          <div class="content">
            <p>
              UMYO Highest tier package- gain full access to umyo’s 16 plus
              websites and features.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Community To Business Branding</div>
          <div class="content">
            <p>
              "Community to Business branding" is a branding strategy that
              focuses on the interaction and relationship between a local or
              online community and a business. It emphasizes building a positive
              and mutually beneficial connection between the two. This approach
              is often associated with small businesses and local enterprises
              that seek to strengthen their ties with the community in which
              they operate"
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Business Network Card</div>
          <div class="content">
            <p>
              “The exclusive business card platform featuring a built-in search
              engine and networking hub that seamlessly connects you with fellow
              community members and entrepreneurs."
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Accountability Cards</div>
          <div class="content">
            <p>
              "I support the idea of joining an organization dedicated to
              community accountability, reporting violent crimes, and
              contributing $5.99 to $19.99 per month as my way of giving back.
              This allows me to enjoy the numerous platforms offered.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">FREE Cards</div>
          <div class="content">
            <p>
              Free membership. Limited accessibility and limited features. Free
              members are not eligible to win daily giveaways. Must be an “IAM
              PLEDGE” member to win!!
            </p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</section>
<!-- Featurs Section End -->

<!-- Footer Start -->
<div
  class="container-fluid bg-dark text-white-50 footer"
  style="background: #474f7a !important"
>
  <div class="container py-5">
    <div class="row g-4">
      <div class="col-lg-4 col-md-6">
        <h4 class="text-light"><b>Contact us</b></h4>
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" href="">Privacy Policy</a>
          <a class="btn-link" href="">Refund Policy</a>
          <a class="btn-link" href="">Terms Of Service</a>
          <a class="btn-link" href="">Terms of Sales</a>
          <a class="btn-link" href="">UMYO Stylist</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-item">
          <h4 class="text-light mb-3">LET'S CONNECT</h4>
          <p class="mb-4">
            Subscribe to stay up to date on all the latest Pro-Living features,
            products, stories, and more. You can also contact us at
            contact@umyoStylist.com.
          </p>
          <div class="position-relative mx-auto">
            <input
              class="form-control border-0 py-2 w-100"
              type="text"
              placeholder="Enter Your Email"
            />
            <button
              type="submit"
              class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
              style="top: 0; right: 0"
            >
              Sent
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="d-flex justify-content-center pt-3">
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="btn btn-outline-secondary btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
      <p class="text-white text-center">
        <a href="#" style="text-decoration: none; color: white"
          ><i class="fas fa-copyright text-light me-2"></i>2024 UMYO Stylist</a
        >, All right reserved.
      </p>
    </div>
  </div>
</div>
<!-- Footer End -->
